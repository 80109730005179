import React, { Component } from 'react'

import { FaCheck, FaExclamationTriangle, FaFrown } from 'react-icons/fa'

import './summary.css'

export default class Summary extends Component {
  render() {
    const { status, title, message } = this.props

    if (status === 'red') {
      return (
        <div className="summary red">
          <h1 className="summary title">
            <FaFrown className="icon" /> {title || 'Major Outage'}
          </h1>
          <h2 className="summary message">
            {message || "We're working hard to restore missing functionality."}
          </h2>
        </div>
      )
    } else if (status === 'orange' || status === 'yellow') {
      return (
        <div className="summary orange">
          <h1 className="summary title">
            <FaExclamationTriangle className="icon" />{' '}
            {title || 'Service Interruption'}
          </h1>
          <h2 className="summary message">
            {message ||
              "We're investigating an issue affecting some of our users."}
          </h2>
        </div>
      )
    }

    return (
      <div className="summary green">
        <h1 className="summary title">
          <FaCheck className="icon" /> {title || 'All systems operational'}
        </h1>
        {message ? <h2 className="summary message">{message}</h2> : null}
      </div>
    )
  }
}
