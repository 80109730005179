import React, { Component } from 'react'
import moment from 'moment-timezone'

import './maintenance.css'

export default class Maintenance extends Component {
  render() {
    const { start, end, message } = this.props

    if (start && end) {
      const startAt = moment(new Date(start))
      const endAt = moment(new Date(end))
      const now = moment()

      if (now.isBefore(endAt)) {
        return (
          <div className="maintenance">
            <h1 className="message">
              {message ||
                'Ombud may be unavailable during this scheduled maintenance window:'}
            </h1>
            <p className="when">
              <strong className="time">
                {startAt.tz(moment.tz.guess()).format('lll z')}
              </strong>
              <small>&nbsp;until&nbsp;</small>
              <strong className="time">
                {endAt.tz(moment.tz.guess()).format('lll z')}
              </strong>
            </p>
          </div>
        )
      }
    }

    return null
  }
}
