import React, { Component } from 'react'

import { FaCheck, FaExclamationTriangle, FaFrown } from 'react-icons/fa'

import './item.css'

export default class Item extends Component {
  render() {
    const { name, status, message } = this.props

    let icon
    if (status === 'red') {
      icon = <FaFrown className="icon iconRed" />
    } else if (status === 'orange' || status === 'yellow') {
      icon = <FaExclamationTriangle className="icon iconOrange" />
    } else {
      icon = <FaCheck className="icon iconGreen" />
    }

    return (
      <div className="item">
        <div>
          <h3 className="name">{name}</h3>
          {message}
        </div>
        {icon}
      </div>
    )
  }
}
