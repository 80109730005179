import React from 'react'

import Layout from '../components/layout'

import Summary from '../components/summary'
import Maintenance from '../components/maintenance'
import Item from '../components/item'

import status from '../status.json'

const IndexPage = () => (
  <Layout>
    <Summary {...status.summary} maintenance={status.maintenance} />
    <Maintenance {...status.maintenance} />
    <div>
      {status.components.map(c => (
        <Item {...c} key={c.name} />
      ))}
    </div>
    <a
      href="https://uptime.statuscake.com/?TestID=Sq6qpuhQ52"
      title="Website Uptime Monitoring"
    >
      <img
        src="https://app.statuscake.com/button/index.php?Track=uZ0ZSaYAgR&Days=1&Design=1"
        alt="Uptime Stat"
      />
    </a>
  </Layout>
)

export default IndexPage
